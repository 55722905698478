@import '../../styles/mixins';

.button {
  background-color: transparent;
  border: none;
  color: $primary;
  cursor: pointer;
  font-size: 15px;
  font-family: 'industry';
  font-weight: bold;
  font-style: italic;
  line-height: 20px;
  display: inline-block;
  padding: 8px 20px;
  outline: none;
  position: relative;
  transition: color 0.3s ease-in-out;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 2;
  &::before {
    background-color: transparent;
    border: 2px solid $primary;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: skewX(-20deg);
    transition: 0.3s ease-in-out;
    z-index: -1;
  }
  &:hover {
    color: #0c1837;
    &::before {
      box-shadow: 0px 5px 20px rgba($primary, 0.2);
      background-color: $primary;

    }
  }
}
