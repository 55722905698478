@import '../../styles//mixins';

.pageHeader {
  background-color: rgba(#141d37, 0.73);
  color: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  height: 83px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: space-between;
  &, & ~ .pageHeaderSpace {
    height: 85px;
  }
  .logoImage {
    width: 117px;
  }
  @include desktop {
    padding: 20px;
    &, & ~ .pageHeaderSpace {
      height: 100px;
    }
    .logoImage {
      width: 170px;
    }
  }
  @include desktop-lg {
    padding-left: 60px;
    padding-right: 60px;
    &, & ~ .pageHeaderSpace {
      height: 120px;
    }
  }
  .mainNavigation {
    font-size: 20px;
    font-weight: 400;
    font-family: industry;
    font-style: italic;
    list-style: none;
    margin: 0;
    text-transform: uppercase;
    @include desktop {
      display: flex;
      font-size: 18px;
    }
    @include desktop-lg {
      font-size: 20px;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include desktop {
        margin: 0 20px;
        a::before {
          content: '';
          background-color: $primary;
          position: absolute;
          top: -20px;
          left: -20px;
          right: -20px;
          height: 10px;
          transform: scaleX(0);
          transition: 0.3s ease-in-out;
        }
      }
      @include desktop-lg {
        margin: 0 30px;
      }
      a:hover {
        color: $primary;
        @include desktop {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    @include tablet {
    }
    .headerSearch {
      background: url(/images/icon-search.svg) center no-repeat;
      background-size: 20px 20px;
      display: inline-block;
      height: 30px;
      margin-right: 20px;
      width: 30px;
    }
    .mobileBars {
      background: url(/images/mobile-menu.svg) center no-repeat;
      display: inline-block;
      height: 40px;
      width: 40px;
      @include desktop {
        display: none;
      }
    }
    .headerButton {
      display: none;
      @include desktop {
        display: block;
      }
    }
  }
  .navigationDrawer {
    @include desktop {
      align-self: stretch;
      display: flex;
    }
    .drawerLogo {
      @include desktop {
        display: none;
      }
    }
  }

  @media (max-width: $desktop-min-width - 1px) {
    .navigationDrawer {
      background-color: rgba(#005aa1, 0.95);
      padding: 20px;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: 300px;
      max-width: 60vw;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: 2;
      &.navigationDrawerOpen {
        transform: translateX(0);
        & + .navigationDrawerOverlay {
          background-color: rgba(#000, 0.85);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }
      .mainNavigation {
        border-top: 1px solid rgba(#141d37, 0.6);
        margin: 20px -20px;
        padding: 0;
        li {
          border-bottom: 1px solid rgba(#141d37, 0.6);
          display: block;
          margin: 0;
          padding: 10px 20px;
          a {
            display: block;
            margin: -10px -20px;
            padding: 10px 20px;
            &:hover {
              background-color: $primary;
              color: #141d37;
            }
          }
        }
      }
    }
  }
}
