$active: #39cabb;
$primary: #ffc710;

$tablet-min-width: 768px;
$desktop-min-width: 992px;
$desktop-lg-min-width: 1200px;
$desktop-xl-min-width: 1800px;

@mixin fulfillment {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  max-width: 100%;
}

@mixin mobile {
  @media (min-width: 400px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-min-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: $desktop-lg-min-width) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: $desktop-xl-min-width) {
    @content;
  }
}
