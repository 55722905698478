@import '../../styles//mixins';

.pageFooter {
  background-color: #005aa1;
  border-top: 5px solid #1f3267;
  color: #97a0cc;
  line-height: 1.5;
  font-size: 16px;
  .footerCols {
    display: grid;
    grid-gap: 40px;
    padding: 40px 0;
    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include desktop-lg {
      grid-gap: 60px;
      grid-template-columns: 280px 1fr 1fr 1fr;
    }
    .footerInfo {
      @include tablet {
        grid-column: 1 / 4;
      }
      @include desktop-lg {
        grid-column: unset;
      }
      img {
        display: block;
        margin: 0 auto;
        @include tablet {
          display: inline-block;
        }
      }
      p {
        margin: 0;
      }
      p + p {
        margin-top: 20px;
      }
      .footerPhone {
        color: $primary;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 20px;
    @include tablet {
      margin-bottom: 35px;
    }
  }
  .footerLinks {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 10px 0;
      }
    }
  }
  .footerContact {
    p {
      color: #fff;
    }
    address {
      font-style: normal;
      margin-bottom: 20px;
    }
    .footerSocial {
      margin-top: 20px;
      .footerSocialFacebook,
      .footerSocialInstagram {
        background: url(/images/icon-social-facebook.svg) center no-repeat;
        background-size: auto 20px;
        display: inline-block;
        margin-right: 10px;
        height: 25px;
        width: 25px;
      }
      .footerSocialInstagram {
        background-image: url(/images/icon-social-instagram.svg);
      }
    }
  }
  .footerBottom {
    background-color: #0c1837;
    padding: 10px 0;
    @include tablet {
      padding: 30px 0;
    }

    .footerBottomLayout {
      text-align: center;
      @include tablet {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      & > div {
        font-weight: 500;
        margin: 15px 0;
        span {
          color: #fff;
        }
        img {
          vertical-align: middle;
        }
      }
    }
  }
}
