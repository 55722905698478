@import '../../styles/mixins';

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  min-height: 100px;
  &.loaderFull {
    background: rgba(#0c1837, 50%);
    border-radius: 10px;
    margin: 0;
    @include fulfillment;
    position: fixed;
  }
}
