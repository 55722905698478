@import './mixins';
@import 'overlayscrollbars/css/OverlayScrollbars.css';

/* Webfont: LatoLatin-Bold */
@font-face {
  font-family: 'Lato';
  src: url('/fonts/LatoLatin-Bold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/LatoLatin-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Bold.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Bold.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Regular */
@font-face {
  font-family: 'Lato';
  src: url('/fonts/LatoLatin-Regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/LatoLatin-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Regular.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: LatoLatin-Italic */
@font-face {
  font-family: 'Lato';
  src: url('/fonts/LatoLatin-Italic.eot'); /* IE9 Compat Modes */
  src: url('/fonts/LatoLatin-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/LatoLatin-Italic.woff2') format('woff2'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Italic.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Italic.ttf') format('truetype');
  font-display: swap;
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

html,
body {
  background-color: #0c1837;
  padding: 0;
  margin: 0;
  font-family: Lato, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

img {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1170px;
}

textarea,
input {
  font-family: Lato, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

.swiper.productsSwiper {
  padding: 0 40px;
  .swiper-button-prev,
  .swiper-button-next {
    background: url(/images/chevron-left.svg) center no-repeat;
    transform: translate(0, -50%);
    z-index: 5;
    &::after {
      content: none;
    }
    &.swiper-button-prev {
      left: 0;
    }
    &.swiper-button-next {
      transform: translate(0, -50%) rotate(180deg);
      right: 0;
    }
  }
}

.homeSwiper {
  & > .swiper-pagination.swiper-pagination-bullets {
    position: absolute;
    left: unset;
    bottom: 20px;
    right: 20px;
    width: unset;
    max-width: 50vw;
    @include tablet {
      bottom: 50px;
      right: 50px;
    }
    .swiper-pagination-bullet {
      background: unset;
      color: #777;
      display: inline-block;
      font-size: 16px;
      font-family: industry;
      font-weight: 300;
      height: unset;
      padding: 5px;
      opacity: 1;
      text-align: center;
      transition: color 0.3s ease-in-out;
      width: unset;
      @include tablet {
        font-size: 20px;
      }
      &:hover {
        color: $primary;
      }
      &.swiper-pagination-bullet-active {
        background-color: unset;
        color: #fff;
        font-weight: 700;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 5;
    background: url(/images/slider-arrow.svg) center no-repeat;
    height: 22px;
    top: unset;
    bottom: 60px;
    transform: none;
    right: unset;
    width: 36px;
    &::after {
      content: none;
    }
    &.swiper-button-prev {
      left: 60px;
    }
    &.swiper-button-next {
      transform: rotate(180deg);
      left: 120px;
    }
  }
}

.categoriesSwiper {
  .swiper-slide {
    width: 350px;
    max-width: 85%;
    @include tablet {
      max-width: 80%;
    }
    @include desktop {
      width: 380px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: $primary url(/images/chevron-right.svg) center no-repeat;
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
    z-index: 1;
    transition: 0.3s ease-in-out;
    @include tablet {
      height: 74px;
      width: 74px;
    }
    &.swiper-button-disabled {
      opacity: 0;
    }
    &::after {
      content: none;
    }
    &.swiper-button-prev {
      left: 20px;
      transform: translateY(-50%) rotate(180deg);
    }
    &:hover {
      background-color: darken($primary, 20%);
    }
  }
}

.os-host.dealersList {
  padding-right: 10px;
  .os-scrollbar {
    background-color: darken(#0c1837, 20%);
    & > .os-scrollbar-track {
      & > .os-scrollbar-handle {
        background-color: $primary;
      }
    }
  }
}

.pageHeading {
  color: #fff;
  font-family: industry;
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  margin: 0 0 40px;
  text-transform: uppercase;
  @include tablet {
    font-size: 40px;
  }
}

.gm-style {
  & > div > div > div > div {
    transition: top 0.25s ease-in-out, left 0.25s ease-in-out,
      width 0.25s ease-in-out, height 0.25s ease-in-out;
    img {
      transition: all 0.25s ease-in-out;
    }
  }
}
