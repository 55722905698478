@import '../../styles/mixins';

.cookiesLayer {
  background-color: rgba(#141d37, 0.73);
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
  &.cookiesLayerVisible {
    opacity: 1;
    pointer-events: all;
  }
  .cookiesLayerWrapper {
    padding: 15px 0;
    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 50px;
      padding: 20px 0;
    }
    @include desktop {
      grid-gap: 100px;
    }
  }
  a {
    color: $primary;
  }
  .cookiesLayerText {
    margin-bottom: 15px;
    @include desktop {
      margin-bottom: 0;
    }
  }
  .cookiesLayerButtons {
    text-align: right;
    .btn {
      box-shadow: none;
    }
  }
}
